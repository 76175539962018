import axios from 'axios';

function pricingDashboardService() {
  let _baseURI;
  let _csrfToken;

  function getModelNumbers(brand, model) {
    return get('sales/model_numbers', { brand, model });
  }

  function getModels(brand) {
    return get('sales/models', { brand });
  }

  function getSales(brand, model, modelNumber) {
    const modelLvl = { brand, model };
    const numberLvl = { ...modelLvl, model_number: modelNumber };

    return get('sales', modelNumber ? numberLvl : modelLvl);
  }

  function get(endpoint, params) {
    const fullURL = `${_baseURI}/${endpoint}`;
    return axios.get(fullURL, { params });
  }

  function init(baseURI, csrfToken) {
    _baseURI = baseURI;
    _csrfToken = csrfToken;
  }

  function post(endpoint, payload) {
    const fullURL = `${_baseURI}/${endpoint}`;
    const req = {
      headers: {
        Accept: '*/*',
        'x-csrf-token': _csrfToken,
      },
    };

    return axios.post(fullURL, payload, req);
  }

  function submitFeedback(payload) {
    return post('feedback', payload);
  }

  function uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    return post('sales/import', formData);
  }

  return {
    getModelNumbers,
    getModels,
    getSales,
    init,
    submitFeedback,
    uploadFile,
  };
}

export default pricingDashboardService;
