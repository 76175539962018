const fetchImports = () => fetch('/imports').then(res => res.json());

const fetchScheduledImports = () => fetch('/imports/scheduled')
  .then(res => res.json());

const signOut = ({ csrfToken }) => {
  const req = {
    method: 'delete',
    headers: {
      Accept: '*/*',
      'x-csrf-token': csrfToken,
    },
  };

  return fetch('users/sign_out', req);
};

export default {
  fetchImports,
  fetchScheduledImports,
  signOut,
};
