<template>
  <md-dialog class="import-errors-dialog"
    :md-active.sync="visible">
    <md-dialog-title>There were {{errors.length}} errors</md-dialog-title>
    <ul>
      <li v-for="(error, i) in errors" :key="i">{{ error }}</li>
    </ul>
  </md-dialog>
</template>

<script>
export default {
  name: 'import-errors-dialog',
  props: {
    errors: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    toggle: {
      type: Function,
      required: true,
    },
  },
  computed: {
    visible: {
      get() { return this.show; },
      set() { this.toggle(); },
    },
  },
};
</script>
