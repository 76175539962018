<template>
  <md-dialog class="spec-price-import-result-dialog"
    :md-active.sync="show">
    <md-dialog-title>Import OK. {{result.imported}}/{{result.total}} spec prices imported</md-dialog-title>
    <h5 class="row-count-details" v-if="showErrors">
        There were some errors.<br/>
        Note: Row count includes headers as first row.
    </h5>
    <div class="import-errors" v-if="showErrors">
      <ul>
        <li v-for="(value, key) in result.errors" :key="key">{{ key }}: {{ importFailMessage(value).message }}</li>
      </ul>
    </div>
    <div v-else>
      <p>All rows imported.</p>
    </div>
    <md-dialog-actions>
      <md-button class="md-primary" @click="onClose">Close</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import importFailMessage from '../services/specPriceImportErrorHandler';

export default {
  name: 'spec-price-import-result-dialog',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Object,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  computed: {
    showErrors,
  },
  methods: {
    importFailMessage: importFailMessage,
  },
};

function showErrors() {
  return Object.keys(this.result.errors).length > 0;
}
</script>

<style>
  p {
    padding: 0px 24px;
  }

  .md-dialog-title {
    margin-bottom: 0px;
  }

  .row-count-details {
    margin: 8px 24px;
    color: rgb(255, 90, 0);
  }

  .import-errors {
    margin: 0px 24px;
    width: 500px;
    min-height: 20px;
    max-height: 160px;
    overflow-x: hidden;
    overflow-x: auto;
    text-align:justify;
  }
</style>
