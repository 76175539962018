<template>
  <md-dialog class="preview-dialog"
    :md-active.sync="show" >
  <md-button class="md-icon-button md-accent close-preview" @click="closeDialog">
    <md-icon>close</md-icon>
  </md-button>
  <img class="image-preview" :src="previewImage" />
  </md-dialog>
</template>

<script>
export default {
  name: 'preview-dialog',
  props: ['closeDialog', 'previewImage'],
  computed: {
    show: {
      get() { return !!this.previewImage },
      set() { this.closeDialog() },
    }
  },
};

</script>

<style>
  .image-preview {
    height: 100% !important;
    object-fit: contain;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .preview-dialog {
    height: 100%;
  }

  .close-preview {
    position: absolute !important;
    top: 10px;
    right: 10px;
    display: block;
  }

  .md-menu-content.md-select-menu {
    z-index: 15;
  }
</style>
