<template>
  <div class="filter-bar-content">
    <FilterSelect v-model="state.selectedSpec"
          label="CCSPEC"
          :blankOption="true"
          :handler="store.actions.handleSpecChange"
          :options="state.specs.filter(x => x)" />
    <md-menu md-direction="bottom-start" md-size="huge" md-align-trigger>
      <md-button md-menu-trigger
        :disabled="!!state.selectedSpec || !state.sales.length">
        Watch Filters
      </md-button>
      <md-menu-content>
        <md-menu-item v-if="!state.selectedSpec">
          <md-button class="md-accent add-filter-btn"
            :disabled="!state.relevantColumns.length"
            @click="addFilterHandler('specs')">
            <md-icon>add</md-icon>
            Add Watch Filter
          </md-button>
        </md-menu-item>
        <md-menu-item class="common-filter" v-for="filter in specFields"
        :key="filter.name">
          <FilterField :filter="filter" />
        </md-menu-item>
      </md-menu-content>
    </md-menu>
    <md-menu md-direction="bottom-start" md-size="huge" md-align-trigger
  :closeOnClick="false">
      <md-button md-menu-trigger
        :disabled="!state.sales.length">
        Listing Filters
      </md-button>
      <md-menu-content>
        <md-menu-item v-if="!state.selectedSpec">
          <md-button class="md-accent add-filter-btn"
            :disabled="!state.relevantColumns.length"
            @click="addFilterHandler('listing')">
            <md-icon>add</md-icon>
            Add Watch Filter
          </md-button>
        </md-menu-item>
        <md-menu-item>
          <PastDaysMenu :value='90' :filter='pastDaysField' />
        </md-menu-item>
        <md-menu-item class="common-filter" v-for="filter in listingFields"
          :key="filter.name">
          <FilterField :filter="filter" />
        </md-menu-item>
      </md-menu-content>
    </md-menu>
    <div style="flex: none; flex-basis: 50px">
    <md-button class="md-icon-button md-accent close-preview"
      :disabled="!state.dirty"
      @click="store.actions.resetFilterDrawer">

      <md-icon>settings_backup_restore</md-icon>
      <md-tooltip md-direction="left">Reset</md-tooltip>
    </md-button>
    </div>
  </div>
</template>

<script>
import FilterField from './FilterField.vue';
import FilterSelect from './FilterSelect.vue';
import PastDaysMenu from './PastDaysMenu';
import { commonFilters, customFilters, salesTableColumns } from '../constants';
import { humanize, unique } from '../helpers';

export default {
  name: 'filter-bar',
  components: { FilterField, FilterSelect, PastDaysMenu },
  props: {
    addFilterHandler: Function,
    filterUpdaterFactory: Function,
    store: Object
  },
  data() {
    const { state } = this.store;
    return { state }
  },
  computed: {
    listingFields() {
      return this.getCommonFields(commonFilters.listingAttributes)
    },
    specFields() {
      return this.getCommonFields(commonFilters.specAttributes)
    },
    pastDaysField,
  },
  methods: {
    filterParamsOptions,
    getCommonFields,
    toField,
  }
};

function getCommonFields(names) {
  return names.map(name => (
    salesTableColumns.find(col => col.name === name))
  ).filter(x => x).map(this.toField);
}

function pastDaysField() {
  const filter = customFilters['past_days']
  return this.toField(filter);
}

function toField(filter) {
  const existing = this.state.filters.find(f => f.name === filter.name );
  const existingValue = existing && existing.params;

  return {
    ...filter,
    label: filter.label || humanize(filter.name),
    options: this.filterParamsOptions(filter),
    value: existingValue,
    handler: this.filterUpdaterFactory(filter)
  };
}

function filterParamsOptions(filter) {
  const vals = this.state.sales.map(sale => sale[filter.name]);
  switch (filter.type) {
    case 'array':
      const flattened = vals.reduce((arr, n) => n ? [...arr, ...n] : arr, []);
      return unique(flattened).sort();
    default:
      return unique(vals).sort();
  }
}

</script>

<style lang="scss" scoped>
.filter-bar-content {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;

  & > div {
    flex: 1;
  }
}

.md-menu {
  flex-basis: 100%;

  .md-button {
    height: 100%;
    width: 100%;
  }

}

.add-filter-btn {
  width: 100%;
}
</style>
