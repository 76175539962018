<template>
  <div class="md-layout md-gutter">
    <div class="md-layout-item">{{label}}</div>
    <div class="md-layout-item md-size-35">{{dataDisplay}}</div>
    <div class="md-layout-item md-size-35">{{filteredDisplay}} </div>
  </div>
</template>

<script>
export default {
  name: 'sales-summary',
  props: ['label', 'data', 'filteredData', 'formatter'],
  computed: {
    dataDisplay() { return this.format(this.data); },
    filteredDisplay() {
      return this.filteredData ? this.format(this.filteredData) : '';
    },
  },
  methods: {
    format,
  },
};

function format(value) {
  if (this.formatter) { return this.formatter(value); }
  return value;
}
</script>
