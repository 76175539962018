function href() {
  return window.location.href;
}

function params() {
  if (window.location.search) return window.location.search;

  const { hash } = window.location;

  if (hash.includes('?')) return hash.slice(hash.indexOf('?'));

  return '';
}

export default {
  params,
  href,
};
