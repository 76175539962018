<template>
  <md-app>
      <md-app-toolbar class="md-primary">
        <router-link to="/pricing" class="md-title">
        <h1>Historic Sales</h1>
        </router-link>
        <div class="header-widget">
          <slot name="header" />
        </div>
        <md-menu md-direction="bottom-start">
          <md-button class="md-accent" md-menu-trigger>
            <md-icon>menu</md-icon>
          </md-button>
          <md-menu-content>
            <router-link to="/pricing">
              <md-menu-item>Pricing Dashboard</md-menu-item>
            </router-link>
            <router-link to="/imports">
              <md-menu-item>Imports</md-menu-item>
            </router-link>
            <a href="/sales_export" v-if="userIsAdmin">
              <md-menu-item>Sales Export</md-menu-item>
            </a>
            <router-link to="/spec_prices">
              <md-menu-item>Spec Prices</md-menu-item>
            </router-link>
            <md-divider />
            <md-menu-item @click="signOut">
              Sign out
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </md-app-toolbar>
      <md-app-toolbar class="md-secondary" v-if="hasSecondarySlot">
        <slot name="secondaryToolbar"></slot>
      </md-app-toolbar>
      <md-app-content>
        <slot name="main"></slot>
      </md-app-content>
  </md-app>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'layout',
  computed: {
    hasSecondarySlot() {
      return !!this.$slots.secondaryToolbar;
    },

    userIsAdmin(){
      return this.$store.state.userRole == "admin";
    }
  },
  methods: {
    signOut,
  },
};

function signOut(){
  this.$store.dispatch('signOut', this.$router);
}
</script>

<style lang="scss" scoped>
.md-app {
    position:fixed !important;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
  }
  .header-widget {
    flex: 2;
  }
  .md-title {
    flex: 1;
    font-size: 1em;
    &:hover {
      text-decoration: none;
    }
  }
</style>
