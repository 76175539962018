<template>
  <div class="md-layout md-alignment-center-center">
      <md-datepicker label="From" v-model="localMin" :md-disabled-dates="disabledMinDates">
        <label>From:</label>
      </md-datepicker>
      <md-datepicker label="To" v-model="localMax" :md-disabled-dates="disabledMaxDates">
        <label>To:</label>
      </md-datepicker>
  </div>
</template>

<script>
import { humanize } from '../helpers';

export default {
  name: 'filter-range',
  props: ['label', 'options', 'value'],
  computed: {
    localValue: {
      get() { return this.value },
      set(newValue) { this.$emit('input', newValue) },
    },
    localMax: {
      get() { return this.value.max || this.options.max  },
      set(newValue) { this.localValue = {...this.value, max: newValue } },
    },
    localMin: {
      get() { return this.value.min || this.options.min },
      set(newValue) { this.localValue = {...this.value, min: newValue } },
    },
  },
  methods: {
    disabledDates,
    disabledMinDates,
    disabledMaxDates,
  },
};

function disabledMinDates(date) {
  return this.disabledDates(date, this.options.min, this.value.max);
}

function disabledMaxDates(date) {
  return this.disabledDates(date, this.value.min, this.options.max);
}

function disabledDates(date, min, max) {
  const belowRange = min && date < min;
  const aboveRange = max && date > max;
  return belowRange || aboveRange;
}

</script>
