<template>
  <md-table-row>
    <md-table-cell :class="status">
      <div class="status-icon">
      <md-button class="md-icon-button md-accent"
        :disabled="status !== 'errors'"
        @click="handleErrorClick(item.importErrors)"
        >
        <md-icon>{{ statusIcon }}</md-icon>
      </md-button>
        <md-tooltip md-direction="left"> {{ status }}</md-tooltip>
      </div>
    </md-table-cell>
    <md-table-cell>
      <md-icon>{{ typeIcon }}</md-icon>
      <span>{{ item.importType.toUpperCase() }}</span>
    </md-table-cell>
    <md-table-cell>
      <span class="md-list-item-text">{{item.date | formatDate}}</span>
    </md-table-cell>
  </md-table-row>
</template>

<script>
const iconMap = {
  success: 'check_circle_outline',
  errors: 'error_outline',
  pending: '360',
  domo: 'import_export',
  ui: 'table_chart',
};

export default {
  name: 'import-list-item',
  props: {
    item: {
      type: Object,
      required: true,
    },
    handleErrorClick: {
      type: Function,
      required: true,
    },
  },
  computed: {
    status() {
      const { processed, successful } = this.item;
      if (successful) return 'success';
      if (processed) return 'errors';
      return 'pending';
    },
    statusIcon() {
      return iconMap[this.status];
    },
    typeIcon() {
      return iconMap[this.item.importType];
    },
  },
};
</script>

<style scoped>
  .success i {
    color: rgb(56, 172, 56) !important;
  }
  .errors i {
    color: rgb(180, 16, 16) !important;
  }
  .pending i {
    color: rgb(184, 184, 184) !important;
  }
  .status-icon {
    display: inline-block;
  }
</style>

