<template>
  <md-dialog class="import-dialog"
    :md-active.sync="show"
    :md-close-on-esc="false"
    :md-click-outside-to-close="false"
  >
    <md-dialog-title>{{ title }}</md-dialog-title>
    <div class="import-progress-container" v-if="processing">
      <md-progress-spinner class="md-accent" md-mode="indeterminate"></md-progress-spinner>
    </div>
    <form class="md-layout" @submit.prevent="submitForm" v-if="!processing">
      <md-field :class="messageClass">
        <label>CSV File</label>
        <md-file @change="assignFile" required />
        <span class="md-error">{{msgs}}</span>
      </md-field>
      <md-card class="md-primary" v-if="msgs">
      </md-card>
      <div>
        <md-button class="md-accent" @click="closeDialog">
          Cancel
        </md-button>
        <md-button type="submit" class="md-primary" :disabled="!selectedFile">
          Import
        </md-button>
      </div>
    </form>
  </md-dialog>
</template>

<script>
export default {
  name: 'import-dialog',
  props: {
    title: {
      type: String,
      default: 'Import Sales',
    },
    show: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
    onSubmit: {
      type: Function,
    },
  },
  data: () => ({
    msgs: null,
    processing: false,
    selectedFile: null,
  }),
  computed: {
    messageClass,
  },
  methods: {
    assignFile,
    closeDialog,
    displayErrors,
    submitForm,
  },
};

function assignFile(e) {
  this.selectedFile = e.target.files[0];
}

function closeDialog() {
  this.selectedFile = null;
  this.processing = false;
  this.msgs = null;
  this.onClose();
}

function displayErrors(res) {
  this.processing = false;
  this.selectedFile = null;
  this.msgs = res.message;
}

function messageClass() {
  return {'md-invalid': !!this.msgs };
}

function submitForm(e) {
  this.processing = true;
  this.onSubmit(this.selectedFile, this.closeDialog, this.displayErrors);
}

</script>

<style>
  .import-dialog {
    width: 50%;
  }

  .import-progress-container {
    width: 100%;
    text-align: center;
  }

  .md-error {
    margin: 0px 20px;
  }
</style>
