<template>
  <md-card id="sales-summary-chart"  class="md-layout-item md-size-60">
    <md-card-content>
      <highcharts ref="highcharts"
         v-if="sales.length"
         :options="options"
      />
    </md-card-content>
  </md-card>
</template>

<script>
import salesChartBuilder from '../services/salesChartBuilder';

export default {
  name: 'sales-summary',
  props: ['handler', 'sales'],
  computed: {
    options: function() {
      return salesChartBuilder(this.sales);
    },
  },
};

</script>
