const comparatorMap = {
  string: sortByString,
  numeric: sortByNumber,
};

function buildComparator({ type, name }, desc) {
  const baseComparator = comparatorMap[type] || defaultSort;

  return (...args) => {
    const vals = args.map(x => x[name]);
    if (desc) vals.reverse();
    return baseComparator(...vals);
  };
}

function defaultSort(a, b) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

function salesSorter(data, sortColumn, desc) {
  if (!sortColumn) return data;

  const comparator = buildComparator(sortColumn, desc);
  return data.sort(comparator);
}

function sortByNumber(a, b) {
  return b - a;
}

function sortByString(a, b) {
  return (a || '').localeCompare(b || '');
}

export default salesSorter;
