<template>
  <md-dialog class="sale-details"
    :md-active.sync="show" >
  <md-button class="md-icon-button md-accent close-dialog" @click="closeDialog">
    <md-icon>close</md-icon>
  </md-button>
  <md-dialog-title>
    {{ displayName }}
    <br />
    SKU: {{ sale.sku }}
  </md-dialog-title>
  
  <md-dialog-content>
    <div class="preview-container">
      <img :src="sale.photo" v-if="sale.photo" />
    </div>
    <ul>
      <li v-for="col in columns.filter(c => c.name !== 'sku')" :key='col.name'>
        <strong>{{ col.label || col.name }}:</strong>
        <span :class="displayValue(col, sale) === 'N/A' ? 'null-data' : ''">
          {{ displayValue(col, sale) }}
        </span>
      </li>
    </ul>
  </md-dialog-content>
  </md-dialog>
</template>

<script>
import { parseColumnData } from '../helpers';

export default {
  name: 'sale-summary',
  props: ['columns', 'sale', 'selectSale'],
  computed: {
    displayName,
    show: {
      get() { return !!this.sale; },
      set() { this.closeDialog(); },
    },
  },
  methods: {
    closeDialog() { this.selectSale(null); },
    displayValue,
  },
};

function displayValue(col, sale) {
    const val = parseColumnData(col, sale);
    const displayUnits = col.units ? ` ${col.units}` : '';
    return val !== null ? `${val}${displayUnits}` : 'N/A';
}

function displayName() {
  return [
    this.sale.brand_name,
    this.sale._model_name,
    this.sale.model_number
  ].join(' ');
}
</script>

<style lang="scss" scoped>
  .sale-details {
    min-width: 30%;

    .preview-container {
      width: 100%;
      text-align: center;
    }

    .null-data {
      color: #BBBBBB;
    }

    img {
      height: 400px;
      width: 400px;
      padding: 1em;
      object-fit: contain;
    }

    ul {
      list-style: none;
      line-height: 2em;
    }
  }

  .close-dialog {
    position: absolute !important;
    top: 10px;
    right: 10px;
    display: block;
  }
</style>
