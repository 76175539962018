import browser from '../services/browserService';
import specPriceService from '../services/specPriceService';
import importFailMessage from '../services/specPriceImportErrorHandler';

function specPriceStore() {
  const service = specPriceService();

  const state = {
    showImportDialog: false,
    showResultDialog: false,
    showHistory: false,
    userRole: null,
  };

  const actions = {
    closeImportDialog,
    closeResultDialog,
    importFile,
    init,
    openImportDialog,
    openResultDialog,
    submitFeedback,
    toggleHistoryTable,
    updateState,
    updateMaxOnHandCount
  };

  function closeImportDialog() {
    updateState({ showImportDialog: false });
  }

  function closeResultDialog() {
    updateState({ showResultDialog: false });
  }

  function importFile(file, success, fail) {
    const errors = validateFile(file);

    if (!errors) {
      service.uploadFile(file)
        .then(response => {
          updateState(
            { showImportDialog: false,
              showResultDialog: true }
          );
          this.$emit('importCompleted', response.data);
          success();
        })
        .catch(error => {
          fail(importFailMessage(error.response.data));
        });
    } else {
      fail(errors);
    }
  }

  function init(data) {
    const {
      baseUri, userRole,
    } = data;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    service.init(baseUri, csrfToken);
    updateState({ userRole: userRole });
  }

  function submitFeedback({ message, from }) {
    const payload = {
      message,
      from,
      url: browser.href(),
    };

    return service.submitFeedback(payload);
  }

  function openImportDialog() {
    updateState({ showImportDialog: true });
  }

  function openResultDialog() {
    updateState({ showResultDialog: true });
  }

  function toggleHistoryTable() {
    updateState({ showHistory: !state.showHistory });
  }

  function updateState(obj) {
    Object.assign(state, obj);
  }

  function updateMaxOnHandCount(payload) {
    service.updateSpecPrice(payload)
  }

  function validateFile(file) {
    if ((file.type === 'application/vnd.ms-excel' && file.name.split('.').pop() == 'csv') || file.type === 'text/csv') {
      return null;
    } else {
      return { message: 'File type must be CSV' };
    }
  }

  return {
    service,
    state,
    actions,
  };
}

export default specPriceStore;
