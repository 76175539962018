<template>
  <md-dialog class="sales-table" :md-active.sync="show">
    <table v-if="!empty" class="fixed-headers">
      <thead>
      <tr class="headers">
        <th></th>
        <th v-for="col in columns"
          @click="sortByColumn(col)"
          :key="col.name"
          :class="sortable(col)">
          <div class="th-container">
            <div class="th-pad">
            </div>
            <div :class="`col-${col.size}`">
              {{col.label}}
            </div>
            <div class="th-pad">
              <md-icon v-if="col === sortColumn">
                {{`keyboard_arrow_${sortDesc ? 'up' : 'down'}`}}
              </md-icon>
            </div>
          </div>
        </th>
      </tr>

      </thead>
      <tbody  class="md-scrollbar">
        <tr v-for="sale in sortedSales" :key="sale.id">
          <td>
            <md-button class="md-icon-button"
              @click="openPreview(sale)">
              <md-icon>assignment</md-icon>
            </md-button>
          </td>
          <td v-for="col in columns" :style="`width: ${col.width}px`" :key="col.name">
            <div :class="`col-${col.size}`">
              {{ parseColumnData(col, sale) }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </md-dialog>
</template>

<script>
import sorter from '../services/salesSorter';
import { parseColumnData } from '../helpers';

export default {
  name: 'sales-table',
  props: ['columns', 'openPreview', 'sales', 'toggle'],
  data() {
    return {
      sortColumn: null,
      sortDesc: false,
    };
  },
  computed: {
    empty() { return !this.sales.length; },
    show: {
      get() { return this.sales && !!this.sales.length},
      set() { this.toggle() },
    },
    sortedSales,
  },
  methods: {
    parseColumnData,
    sortable,
    sortByColumn,
  },
};

function sortable(col) {
  return col.type !== 'array' ? 'sortable' : '';
}

function sortByColumn(col) {
  if (sortable(col)) {
    const key = this.sortColumn && this.sortColumn.name;

    this.sortDesc = col.name && col.name === key ? !this.sortDesc : false;
    this.sortColumn = col;
  }
}

function sortedSales() {
  return sorter(this.sales, this.sortColumn, this.sortDesc);
}
</script>

<style lang="scss" scoped>
.md-dialog {
  width: 100%;
  height: 30%;
  overflow: auto;
}

$header_background_color: #333;
$header_text_color: #FDFDFD;
$alternate_row_background_color: #DDD;

$table_width: 100%;
$table_head_height: 42px;
$table_body_height: 800px;
$column_one_width: 63px;
$column_two_width: 200px;
$column_three_width: 350px;

.sales-table {
  height: 845px;
  width: 80%;
}

.fixed-headers {
  table-layout: fixed;
  height: 50%;
  border-collapse: collapse;
  overflow-x: auto;

  th, td {
    text-align: center;
    border: solid #b5b5b5;
    border-width: 0 1px;

    &:nth-child(1) {
      min-width: $column_one_width;
      border-left: 0;
    }

    &:nth-last-child(1) {
      border-right: 0;
    }
  }

  th {
    .col-sm { width: 60px; }
    .col-md { width: 110px; }
    .col-lg { width: 180px; }
    .col-xl { width: 300px; }
  }

  td {
    .col-sm { width: 110px; }
    .col-md { width: 160px; }
    .col-lg { width: 230px; }
    .col-xl { width: 350px; }
  }

  thead {
    background-color: $header_background_color;
    color: $header_text_color;
    tr {
      display: block;
      position: relative;
    }
    i {
      color: $header_text_color !important;
    }
    .sortable {
      cursor: pointer;
    }
    .th-container {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
    .th-pad {
      width: 25px;
    }
  }
  tbody {
    display: block;
    overflow-y: auto;
    height: $table_body_height;
    tr:nth-child(even) {
      background-color: $alternate_row_background_color;
    }
  }
}
</style>
