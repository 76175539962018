const setCsrf = (state, csrfToken) => {
  state.csrfToken = csrfToken;
};

const setImports = (state, importsList) => {
  state.importsList = importsList;
};

const setScheduledImports = (state, importsList) => {
  state.scheduledImportsList = importsList;
};

const setUserRole = (state, userRole) => {
  state.userRole = userRole;
};

export default {
  setCsrf,
  setImports,
  setScheduledImports,
  setUserRole
};
