<template>
  <div>
    <label class="md-layout" v-if="label">{{label}}</label>
    <div class="md-layout md-alignment-center-center">
      <md-field :class="minValid">
        <label>Min{{ displayUnits }}</label>
        <md-input v-model="localMin" type="number"
        :min="minValue"
        :max="maxValue" />
        <span class="md-error">{{ minErrors }} </span>
      </md-field>
      <div class="md-layout-item md-size-10 center">
        to
      </div>
      <md-field :class="maxValid">
        <label>Max{{ displayUnits }}</label>
        <md-input v-model="localMax" type="number"
        :min="minValue"
        :max="maxValue" />
        <span class="md-error">{{ maxErrors }}</span>
      </md-field>
    </div>
  </div>
</template>

<script>
import { humanize, toNumber } from '../helpers';

export default {
  name: 'filter-range',
  props: ['handler', 'humanized', 'label', 'multiple', 'options', 'units', 'value'],
  computed: {
    empty() { return this.options && !this.options.length; },
    displayUnits() {
      return this.units ? ` (${this.units})` : '';
    },
    localValue: {
      get() { return this.value; },
      set(newValue) { this.$emit('input', newValue) }
    },
    localMin: {
      get() { return this.value.min },
      set: setMin,
    },
    localMax: {
      get() { return this.value.max },
      set: setMax,
    },
    maxErrors() {
      const checks = [
        v => {
          if (v < this.minValue)
            return 'No data found matching parameters';

          const msg = `can't be less than higher range: ${this.localMin}`
          return this.localMin && v < this.localMin && msg;
        },

      ];
      return applyChecks(this.localMax, checks);
    },
    maxValid() {
      const errorClass = this.maxErrors ? ' md-invalid' : '';
      return `md-layout-item${errorClass}`;
    },
    maxValue() {
      return Math.max(...this.options);
    },
    minErrors() {
      const checks = [
        v => {
          if (v > this.maxValue)
            return 'No data found matching parameters';

          const msg = `can't be greater than higher range: ${this.maxValue}`
          return this.localMax && v > this.localMax && msg;
        },
      ];
      return this.localMin ? applyChecks(this.localMin, checks) : '';
    },
    minValid() {
      const errorClass = this.minErrors ? ' md-invalid' : '';
      return `md-layout-item${errorClass}`;
    },
    minValue() {
      return Math.min(...this.options);
    },
  },
};

function applyChecks(value, checks) {
  if (!value && value !== 0) return;

  const errors = checks.reduce((allErrors, checker) => {
    const error = checker(value);
    return error ? [...allErrors, error] : allErrors;
  }, []).join();

  return value && errors ? errors : null;
}

function setMin(newValue) {
  this.localValue = {...this.value, min: toNumber(newValue) };
}

function setMax(newValue) {
  this.localValue = {...this.value, max: toNumber(newValue) };
}
</script>

<style scoped>
.center {
  text-align: center;
}
</style>
