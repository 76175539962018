import { formatMoney, formatDate } from '../helpers';
import {
  specPriceTableColumns,
  specPriceHistorySpecificTableColumns,
} from '../constants';

function specPriceDataFormatter(data) {
  data.forEach(formatObject);
}

function formatObject(object, index) {
  const columns = specPriceHistoryTableColumns();

  for (let key in object) {
    let column = columns.find(obj => obj.name == key);
    if(column) {
      switch (column.type) {
        case 'date':
          object[key] = object[key] ? formatDate(object[key]) : 'Active';
          break;
        case 'money':
          object[key] = convertMoney(key, object[key]);
          break;
      }
    }
  }
}

// This will help handle special cases for now
function convertMoney(key, value) {
  if (key == "procurement_price" && value == -1) {
    return "BOT";
  } else {
    return formatMoney(value);
  }
}

function specPriceHistoryTableColumns() {
  return [...specPriceTableColumns, ...specPriceHistorySpecificTableColumns];
}

export default specPriceDataFormatter;
