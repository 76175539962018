<template>
  <md-field>
    <label>{{label}}</label>
    <md-select v-model="localValue"
       :disabled="empty"
       :multiple='multiple'
       @input="handler">
      <md-option value="" v-if="blankOption"></md-option>
      <md-option v-for="(o, i) in options" :value="o" :key="i">
        {{optionDisplay(o)}}
      </md-option>
    </md-select>
  </md-field>
</template>

<script>
import { humanize } from '../helpers';

export default {
  name: 'filter-select',
  props: [
    'blankOption',
    'handler',
    'humanized',
    'label',
    'multiple',
    'options',
    'value',
  ],
  computed: {
    empty() { return this.options && !this.options.length; },
    localValue: {
      get() { return this.value; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
  methods: {
    optionDisplay,
  },
};

function optionDisplay(val) {
  return this.humanized ? humanize(val) : val;
}
</script>
