<template>
  <md-dialog class="filter-dialog"
    :md-active.sync="visible">
  <md-dialog-title>Feedback</md-dialog-title>
  <md-dialog-content>
    <md-field>
      <label>Email</label>
      <md-input v-model="from" type="email"></md-input>
    </md-field>
    <md-field>
      <label>Message</label>
      <md-textarea v-model="message"></md-textarea>
    </md-field>
    <md-progress-bar class="md-accent"
      md-mode="indeterminate"
      v-if="processing" />
    {{ error }}
  </md-dialog-content>
  <md-dialog-actions>
    <md-button class="md-primary" @click="closeDialog" :disabled="processing">
      Close
    </md-button>
    <md-button class="md-accent"
      :disabled="!validForm"
      @click="submitForm">
      Save
    </md-button>
  </md-dialog-actions>
</md-dialog>
</template>

<script>
import { validEmailRegex } from '../constants';

export default {
  name: 'feedback-dialog',
  props: ['show', 'submit', 'toggle'],
  data() {
    return {
      error: '',
      from: '',
      message: '',
      processing: false,
    };
  },
  computed: {
    validEmail,
    validForm,
    visible: {
      get() { return this.show; },
      set() { this.closeDialog(); },
    },
  },
  methods: {
    closeDialog,
    submitForm,
  },
};

function closeDialog() {
  if (!this.processing) {
    Object.assign(this, { message: '', from: '', error: '' });
    this.toggle(false);
  }
}

function validEmail() {
  return validEmailRegex.test(String(this.from).toLowerCase());
}

function validForm() {
  return this.validEmail && this.message && !this.processing;
}

function submitForm() {
  this.processing = true;
  return this.submit({
    from: this.from,
    message: this.message,
  }).then((res) => {
    this.processing = false;
    this.closeDialog();
  }).catch((e) => {
    this.error = 'There was an error submitting your feedback';
    this.processing = false;
  });
}

</script>

<style lang="scss" scoped>
</style>
