import { genderMap } from './constants';

export function capitalize(str) {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}

export function formatDate(date, timeZone = 'UTC') {
  return (new Date(date)).toLocaleDateString('en-US', { timeZone });
}

export function formatMoney(value, cents = false) {
  const opts = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: cents ? 2 : 0,
  };

  const valueCents = cents ? value : Math.round(value);

  return value || value === 0 ? valueCents.toLocaleString('en-US', opts) : null;
}

export const formatPercent = (value = 0, decimals = 2) => {
  const p = (value * 100).toFixed(decimals);
  return `${p}%`;
};

export function humanize(str) {
  return str.split('_')
    .filter(Boolean)
    .map(capitalize)
    .join(' ');
}

export function parseColumnData(col, row) {
  const data = row[col.name];
  switch (col.type) {
    case 'array':
      return (data || []).join(', ') || null;
    case 'boolean':
      return toYesNo(data);
    case 'date':
      return formatDate(data);
    case 'exhibition':
      return toYesNo(data && data.toLowerCase() === 'exhibition');
    case 'gender':
      return genderMap[data];
    case 'money':
      return formatMoney(data);
    case 'numeric':
    case 'year':
      return toNumber(data);
    default:
      return data;
  }
}

export function toNumber(val) {
  if (!val && val !== 0) return null;
  return Number(val.toString().replace(/[^\d.]/g, '')) || null;
}

export function toYesNo(bool) {
  return bool ? 'Yes' : 'No';
}

export function unique(arr) {
  return Array.from(new Set(arr));
}
