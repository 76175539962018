import {
  specPriceTableColumns,
  specPriceImportErrors,
} from '../constants';

function importFailMessage(response) {
  if (typeof response == 'string') {
    return response;
  }

  let result = { message: null };

  // Convert error code to a readable one if applies
  const code = specPriceImportErrors[response.code] || response.code;

  // Convert payload to readable based on error code
  let payload;

  switch(response.code) {
    case 'csv_missing_headers':
      payload = missingHeaders(response.payload);
      break;
    case 'validation_error':
      payload = validationError(response.payload);
      break;
    default:
      payload = defaultPayloadConverter(response.payload);
  }

  result.message = code;
  if (payload) {
    result.message = result.message + ': ' + payload;
  }

  return result;
}

function missingHeaders(payload) {
  if (payload.length == specPriceTableColumns.length) {
    return 'There are no headers. Are you sure it\'s a valid CSV?'
  }

  let headers = [];
  for (let key in payload) {
    headers.push(specPriceTableColumns.find(obj => obj.name == payload[key]).title);
  }
  return headers.join(', ');
}

function validationError(payload) {
  if (typeof payload == String) {
    return payload;
  }

  let errors = [];

  for (let key in payload) {
    errors.push(
      specPriceTableColumns.find(obj => obj.name == key).title +
      ' ' +
      payload[key].join(', ')
    );
  }
  return errors.join(', ');
}

function defaultPayloadConverter(payload) {
  if (payload == 'null') {
    return null;
  }

  return payload;
}

export default importFailMessage;
