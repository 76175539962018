function avg(data) {
  return sum(data) / data.length;
}

function median(data) {
  const sorted = data.sort((a, b) => a - b);
  const midpoint = data.length / 2;

  if (midpoint % 1) return sorted[Math.floor(midpoint)];

  const upper = sorted[midpoint];
  const lower = sorted[midpoint - 1];

  return (lower + upper) / 2;
}

function mode(data) {
  const counts = data.reduce((obj, val) => ({
    ...obj,
    [`${val}`]: (obj[`${val}`] || 0) + 1,
  }), {}) || [];

  const highCount = Math.max(...Object.values(counts));
  const highCountVals = Object.keys(counts)
    .reduce((vals, key) => {
      if (counts[key] === highCount) { return [...vals, +key]; }
      return vals;
    }, []);

  return Math.max(...highCountVals);
}

function precisionRound(number, precision = 0) {
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
}

function stdev(set, mean) {
  const mu = mean || avg(set);
  const num = sum(set, x => (x - mu) ** 2);
  const den = set.length - 1;
  return precisionRound(Math.sqrt(num / den), 2);
}

function sum(arr, f) {
  return arr.reduce((accum, x) => accum + (f ? f(x) : x), 0);
}

function dateCompareDesc(a, b) {
  return Date.parse(b) - Date.parse(a);
}

function rSquared(data = [], f) {
  const mu = avg(data.map(s => s.y));
  const SSR = sum(data, s => (f(s.x) - mu) ** 2);
  const SSTO = sum(data, s => (s.y - mu) ** 2);
  return precisionRound(SSR / SSTO * 100, 1);
}

function regression(data = []) {
  const sumX = sum(data, s => s.x);
  const sumY = sum(data, s => s.y);
  const sumXsq = sum(data, s => s.x ** 2);
  const sumXy = sum(data, s => s.y * s.x);
  const n = data.length;
  const a = ((sumY * sumXsq) - (sumX * sumXy)) / (n * sumXsq - sumX ** 2);
  const b = (n * sumXy - sumX * sumY) / (n * sumXsq - sumX ** 2);

  return x => a + b * x;
}

function calculateDiscount({ discounted_price, purchase_price }) {
  return purchase_price - (discounted_price || purchase_price);
}

function summarizeSales(data) {
  if (!data.length) return {};

  const prices = data.map(x => x.purchase_price);
  // const discounts = data.map(calculateDiscount).filter(x=>x);
  const discounts = data.map(calculateDiscount).filter(x=>x);
  const discountFreq = discounts.length / data.length;
  const average = avg(prices);
  const averageDiscount = avg(discounts);
  const dateSorted = data
    .sort((a, b) => dateCompareDesc(a.purchased_date, b.purchased_date));
  const earliestSale = dateSorted[dateSorted.length - 1] || {};
  const lastSale = dateSorted[0];

  return {
    average,
    averageDiscount,
    discountFreq,
    median: median(prices),
    mode: mode(prices.map(Math.round)),
    stdev: stdev(prices, average),
    min: Math.min(...prices),
    max: Math.max(...prices),
    avgDaysOnMarket: precisionRound(avg(data.map(x => x.days_on_market), 1)),
    earliestSaleDate: earliestSale.purchased_date,
    lastSaleDate: lastSale.purchased_date,
    lastSalePrice: lastSale.purchase_price,
  };
}

export default {
  avg,
  precisionRound,
  regression,
  rSquared,
  stdev,
  summarizeSales,
};
