<template>
  <div class="filter-field">
    <FilterSelect :label="filter.label"
      v-model="localValue"
      v-if="filter.type === 'array'"
      multiple
      :options="filter.options"
    />

    <FilterSelect :label="filter.label"
      v-model="localValue"
      v-if="filter.type === 'string'"
      multiple
      :options="filter.options"
    />

    <FilterRange :label="filter.label"
      v-model="localValue"
      v-if="filter.type === 'numeric'"
      :units="filter.units"
      :options="filter.options"
    />

    <md-switch v-model="localValue"
      v-if="filter.type === 'boolean'"
      :value="true">
      <label>{{filter.label}}:</label>
      {{ filter.value ? 'Yes' : 'No' }}
    </md-switch>
  </div>
</template>

<script>
import FilterDateRange from './FilterDateRange.vue';
import FilterRange from './FilterRange.vue';
import FilterSelect from './FilterSelect.vue';

export default {
  name: 'filter-field',
  components: { FilterDateRange, FilterRange, FilterSelect },
  props: [ 'filter' ],
  computed: {
    isRange,
    localValue: { get: getFilterValue, set: setFilterValue }
  },
  methods: { },
};

function isRange() {
  return ['numeric', 'date'].includes(this.filter.type);
}

function getFilterValue() {
  const {value} = this.filter;

  if (this.isRange) {
    return value || {min: null, max: null}
  }

  return value;
}

function setFilterValue(newValue) {
  this.filter.handler(newValue)
  Object.assign(this.filter, {value: newValue});
}
</script>

<style>
.filter-field {
  flex-basis: 100%;
}
</style>
