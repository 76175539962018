<template>
  <md-content id="sales-summary">
    <div class="md-layout">
      <div class="md-layout-item md-size-30">
        <div class="md-title">
          Data Summary
        </div>
        <span>{{sales.length}}{{filteredCount}} items found</span>
      </div>
      <div class="md-layout-item">
        <md-button class="md-accent" v-if="filters.length"
          @click="clearFilters">
          Clear All
        </md-button>
        <md-chip class="md-accent" v-for="filter in filters"
          @md-delete="removeFilter(filter)"
          :key="filter.name"
          md-deletable>
          {{ chipDisplay(filter) }}
        </md-chip>
      </div>
    </div>
    <md-divider />
    <br />

    <div class="md-layout md-alignment-center-center fetching" v-if="loading">
        <md-progress-spinner class="md-accent"
          :md-diameter="200"
          :md-stroke="30"
          md-mode="indeterminate" />
    </div>

    <div class="md-layout" v-if="!loading && sales.length" >
      <md-card id="sales-summary-table"  class="md-layout-item  md-size-30">
        <md-card-content v-if="!empty">
          <SummaryRow
            :data="dataLevel"
            :filteredData="filterLabel"
            />
          <md-divider />
          <SummaryRow label="Average"
            :data="summary.average"
            :filteredData="filteredSummary.average"
            :formatter="formatMoney"
            />
          <SummaryRow label="Median"
            :data="summary.median"
            :filteredData="filteredSummary.median"
            :formatter="formatMoney"
            />
          <SummaryRow label="Mode"
            :data="summary.mode"
            :filteredData="filteredSummary.mode"
            :formatter="formatMoney"
            />
          <SummaryRow label="Std. Dev."
            :data="summary.stdev"
            :filteredData="filteredSummary.stdev"
            :formatter="formatMoney"
            />
          <SummaryRow label="Min. Sale"
            :data="summary.min"
            :filteredData="filteredSummary.min"
            :formatter="formatMoney"
            />
          <SummaryRow label="Max Sale"
            :data="summary.max"
            :filteredData="filteredSummary.max"
            :formatter="formatMoney"
            />
          <md-divider />
          <SummaryRow label="Average Discount"
            :data="summary.averageDiscount"
            :filteredData="filteredSummary.averageDiscount"
            :formatter="formatMoney"
            />
          <SummaryRow label="Discount Frequency"
            :data="summary.discountFreq"
            :filteredData="filteredSummary.discountFreq"
            :formatter="formatPercent"
            />
          <md-divider />
          <SummaryRow label="Avg. Days on Mkt."
            :data="summary.avgDaysOnMarket"
            :filteredData="filteredSummary.avgDaysOnMarket"
            />
          <md-divider />
          <SummaryRow label="Last Sale Price"
            :data="summary.lastSalePrice"
            :filteredData="filteredSummary.lastSalePrice"
            :formatter="formatMoney"
            />
          <SummaryRow label="Earliest Sale Date"
            :data="summary.earliestSaleDate"
            :filteredData="filteredSummary.earliestSaleDate"
            :formatter="formatDate"
            />
          <SummaryRow label="Last Sale Date"
            :data="summary.lastSaleDate"
            :filteredData="filteredSummary.lastSaleDate"
            :formatter="formatDate"
            />

        </md-card-content>
      </md-card>

      <SalesChart :sales="filteredSales" :handler="chartClickHandler" />
    </div>
  </md-content>
</template>

<script>
  import { formatDate, formatMoney, formatPercent } from '../helpers';
  import SalesChart from './SalesChart.vue';
  import SummaryRow from './SummaryRow.vue';

  export default {
    name: 'sales-summary',
    props: [
      'chartClickHandler',
      'clearFilters',
      'columns',
      'dataLevel',
      'loading',
      'filteredSales',
      'filteredSummary',
      'filters',
      'removeFilter',
      'sales',
      'spec',
      'summary'
    ],
    components: { SalesChart, SummaryRow },
    computed: {
      empty() { return !this.sales.length},
      filteredCount,
      filterLabel,
    },
    methods: {
      chipDisplay,
      formatDate,
      formatMoney,
      formatPercent,
    },
  }

  function chipDisplay({label, comparator, params, labelTemplate }) {
    const displayMap = {
      includes: v => v.join(', '),
      inRange: displayRangeText,
      dateRange: displayDateRangeText,
    }
    const displayer = displayMap[comparator];
    const value = displayer ? displayer(params) : params;

    if (labelTemplate)
      return labelTemplate.replace('?', value);

    return `${label}: ${value}`;
  }

  function displayDateRangeText({min, max}) {
    return `${formatDate(min)} - ${formatDate(max)}`
  }

  function displayRangeText({min, max}) {
    if (!min && min !== 0) return `< ${max}`;
    if (!max && max !== 0) return `> ${min}`;
    return `${min} - ${max}`;

  }
  function filteredCount() {
    const show = this.filteredSales.length !== this.sales.length;
    return show ?  ` (${this.filteredSales.length})` : '';
  }

  function filterLabel() {
    const filtered = !!this.filters.length
    if (this.spec)
      return filtered ? `${this.spec} (Filtered)` : this.spec;
    if (this.filters.length)
      return 'Filtered';
  }
</script>

<style lang="scss" scoped>

#sales-summary {
    .fetching {
      height: 75%;
    }
}

.md-content {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.md-card {
  display: inline-block;
}

#sales-summary-table {
  min-width: 250px;
}
</style>
