<template>
  <div>
    <Layout>
      <div slot="header" class="pd-widget">
        <md-autocomplete id="model-number-search"
          md-layout="box"
          md-dense
          :md-options="state.modelNumbers"
          :value="explodeModelNumber(state.selectedModelNumber)"
          @md-selected="store.actions.handleModelNumberChange">
          <label>Model Number</label>
          <template slot="md-autocomplete-item" slot-scope="{ item }">
            {{ explodeModelNumber(item) }}
          </template>
        </md-autocomplete>
        <div class="pd-widget-buttons">
          <md-button class="md-accent" @click="toggleTableDialog" :disabled="!state.sales.length">
              <md-icon>list_alt</md-icon>
          </md-button>
          <md-button class="md-accent" @click="toggleFeedbackDialog(true)">
            Feedback
          </md-button>
        </div>
      </div>
        <FilterBar slot="secondaryToolbar"
          :store="store"
          :addFilterHandler="toggleFilterMode"
          :filterUpdaterFactory="store.actions.filterUpdaterFactory" />
        <SalesSummary slot="main"
          :sales="state.sales"
          :chartClickHandler="selectSale"
          :clearFilters="store.actions.clearFilters"
          :columns="state.columns"
          :dataLevel="state.dataLevel"
          :loading="state.fetching"
          :filteredSales="state.filteredSales"
          :filteredSummary="state.filteredSalesSummary"
          :filters="state.filters"
          :removeFilter="store.actions.removeFilter"
          :spec="state.selectedSpec"
          :summary="state.salesSummary" />
    </Layout>

    <SaleDetails
      v-if="activeSale"
      :columns="state.columns"
      :sale="activeSale"
      :selectSale="selectSale"
      />

    <SalesTable
        v-if="state.showTable && !state.fetching"
        :columns="state.columns"
        :openPreview="selectSale"
        :toggle="store.actions.toggleTableDialog"
        :sales="state.filteredSales" />

    <FilterDialog
      :mode="filterDialogMode"
      :onClose="toggleFilterMode"
      :onSubmit="store.actions.addFilter"
      :relevantColumns="state.relevantColumns"
      :sales="state.filteredSales"
    />

    <FeedbackDialog
      :show="feedbackDialogVisible"
      :submit="submitFeedback"
      :toggle="toggleFeedbackDialog"
    />

    <ImportDialog :show="state.showDialog"
      :onSubmit="importFile"
      :onClose="closeDialog"
    />
  </div>
</template>

<script>
import Layout from './Layout.vue';
import FeedbackDialog from './FeedbackDialog.vue';
import FilterBar from './FilterBar.vue';
import FilterDialog from './FilterDialog.vue';
import FilterSelect from './FilterSelect.vue';
import ImportDialog from './ImportDialog.vue';
import PreviewDialog from './PreviewDialog.vue';
import SaleDetails from './SaleDetails.vue';
import SalesSummary from './SalesSummary.vue';
import SalesTable from './SalesTable.vue';
import pricingDashboardStore from '../stores/pricingDashboardStore.js';

export const store = pricingDashboardStore();

export default {
  name: 'pricing-dashboard',
  components: {
    FeedbackDialog,
    FilterBar,
    FilterDialog,
    FilterSelect,
    ImportDialog,
    Layout,
    PreviewDialog,
    SaleDetails,
    SalesSummary,
    SalesTable,
  },
  props: {
    baseUri: String,
    brands: Array,
    models: Array,
    modelNumbers: Array,
    specs: Array,
    userRole: String,
  },
  beforeMount() {
    store.actions.init(this.$props);
    this.$store.commit("setUserRole", this.userRole)
  },
  data: () => ({
    store,
    state: store.state,
    filterDialogMode: null,
    activeSale: null,
    feedbackDialogVisible: false,
  }),
  computed: {
    canImport,
  },
  methods: {
    changeModelNumber: store.actions.handleModelNumberChange,
    closeDialog: store.actions.hideImportDialog,
    explodeModelNumber,
    importFile: store.actions.importFile,
    selectSale,
    showImportDialog: store.actions.showImportDialog,
    toggleTableDialog: store.actions.toggleTableDialog,
    submitFeedback: store.actions.submitFeedback,
    toggleFeedbackDialog,
    toggleFilterMode,
  },
};

function canImport() {
  return this.userRole === 'admin';
}

function explodeModelNumber(obj) {
  if (!obj || !obj.model_number)
    return '';
  return `${obj.brand_name} - ${obj._model_name} - ${obj.model_number}`
}

function selectSale(sale) {
  this.activeSale = sale;
}

function toggleFilterMode(mode) {
  this.filterDialogMode = mode;
}

function toggleFeedbackDialog(bool=false) {
  this.feedbackDialogVisible = bool;
}
</script>

<style lang="scss" scoped>
  #model-number-search {
    flex-basis: 75%;
  }

  .md-disabled .md-icon{
    color: rgb(29, 29, 29) !important;
  }

  .pd-widget {
    display: flex;
  }

  .pd-widget-buttons {
    flex-basis: 25%;
  }


</style>
