<template>
  <md-dialog class="filter-dialog"
    :md-active.sync="show"
    :md-close-on-esc="false"
    :md-click-outside-to-close="false">
  <md-dialog-title>Add Filter</md-dialog-title>
  <md-dialog-content>
    <form class="md-layout" @submit.prevent="submitForm">
      <div class="md-layout md-layout-item md-size-100">
        <div class="md-layout-item md-size-35">
          <FilterSelect label="Attribute"
            v-model="selectedComparator"
            :handler="handleFilterSelection"
            :humanized="true"
            :options="filterOptions" />
        </div>
        <div class="md-layout-item">
          <FilterSelect label="Options"
            v-model="filterParams"
            v-if="activeComparator === 'string'"
            :options="filterParamsOptions"
          />

          <FilterSelect label="Options"
            v-model="filterParams"
            v-if="activeComparator === 'array'"
            multiple
            :options="filterParamsOptions"
          />

          <md-switch v-model="filterParams"
            v-if="activeComparator === 'boolean'"
            :disabled="disabled"
            :value="true">
            {{ filterParams ? 'Yes' : 'No' }}
          </md-switch>

          <FilterRange label="Options"
            v-model="rangeParams"
            v-if="activeComparator === 'numeric'"
            :units="activeFilter.units"
            :options="filterParamsOptions"
          />

          <FilterDateRange label="Options"
            v-if="activeComparator === 'date'"
            v-model="rangeParams"
            :options="filterParamsOptions"
          />
        </div>
      </div>
      <div class="md-layout-item md-size-100">
        <md-button class="md-accent" @click="closeDialog">
          Cancel
        </md-button>

        <md-button type="submit" class="md-primary" :disabled="disabled">
          Add
        </md-button>
      </div>
    </form>
  </md-dialog-content>
</md-dialog>
</template>

<script>
import FilterDateRange from './FilterDateRange.vue';
import FilterRange from './FilterRange.vue';
import FilterSelect from './FilterSelect.vue';
import { unique } from '../helpers';
import {
  comparatorMap,
  listingAttributes,
  salesTableColumns,
  specAttributes,
} from '../constants';

export default {
  name: 'filter-dialog',
  components: { FilterDateRange, FilterRange, FilterSelect },
  props: {
    mode: {
      type: String,
      default: '',
    },
    relevantColumns: {
      type: Array,
    },
    sales: {
      type: Array,
    },
    onClose: {
      type: Function,
    },
    onSubmit: {
      type: Function,
    },
  },
  data: () => ({
    filterParams: null,
    rangeParams: { min: null, max: null },
    formValid: false,
    selectedComparator: '',
  }),
  computed: {
    activeFilter,
    activeComparator,
    disabled,
    filterOptions,
    filterParamsOptions,
    show() { return !!this.mode; },
  },
  methods: {
    closeDialog,

    handleFilterSelection,
    submitForm,
  },
};

function activeFilter() {
  return salesTableColumns.find(col => col.name === this.selectedComparator);
}

function activeComparator() {
  return this.activeFilter && this.activeFilter.type;
}

function closeDialog() {
  this.selectedComparator = '';
  this.onClose();
}

function disabled() {
  const bypassedTypes = ['boolean', 'date', 'numeric'];
  if (bypassedTypes.includes(this.activeComparator)) return false;

  const hasOptions = this.filterParamsOptions && this.filterParamsOptions.length > 1
  const isDisabled = !(this.activeFilter && this.filterParams && hasOptions);
  return isDisabled;
}

function filterOptions() {
  const { mode } = this;
  if (!mode) return [];
  const colList = mode === 'specs' ? specAttributes : listingAttributes;
  return colList.filter(x => this.relevantColumns.includes(x));
}

function filterParamsOptions() {
  if (!this.activeFilter) return;
  const vals = this.sales.map(sale => sale[this.selectedComparator]);
  switch (this.activeFilter.type) {
    case 'array':
      const flattened = vals.reduce((arr, n) => [...arr, ...n], [])
      return unique(flattened).sort();
    case 'date':
      const min = new Date(Math.min(...vals.filter(Boolean).map(Date.parse)));
      const max = new Date(Math.max(...vals.filter(Boolean).map(Date.parse)));
      return { min, max };
    default:
      return unique(vals).sort();
  }
}

function handleFilterSelection() {
  this.filterParams = this.activeComparator === 'boolean' ? false : null;
  this.rangeParams = { min: null, max: null };
}

function submitForm() {
  const comparator = comparatorMap[this.activeComparator] || 'eq';
  const isRangeComparator = ['date', 'numeric'].includes(this.activeComparator);
  const params = isRangeComparator ? this.rangeParams : this.filterParams;
  const name = this.selectedComparator;
  this.onSubmit({
    name,
    target: name,
    comparator,
    params,
  });
  this.closeDialog();
}

</script>

<style>
  .filter-dialog {
    width: 75%;
    height: 75%;
  }

  .md-menu-content.md-select-menu {
    z-index: 15;
  }
</style>
