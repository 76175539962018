<template>
  <Layout>
    <div slot="main">
      <md-progress-bar class="md-accent"
        md-mode="indeterminate"
        v-show="loading"
      />
      <md-table>
        <md-table-row>
          <md-table-head>Scheduled Imports</md-table-head>
        </md-table-row>
        <md-table-row v-if="!!scheduledImportsList.length"
          v-for="item in scheduledImportsList"
          :key="item.id">
          <md-table-cell>
            <md-icon>schedule</md-icon>
            {{item.nextRun | formatDate}}
          </md-table-cell>
        </md-table-row>
        <md-table-row v-if="!scheduledImportsList.length">
          <md-table-cell>
            None scheduled
          </md-table-cell>
        </md-table-row>
      </md-table>

      <md-divider class="separate-tables" />

      <md-table>
        <md-table-row>
          <md-table-head>Past Imports</md-table-head>
        </md-table-row>
        <md-table-row>
          <md-table-head>
            Status
          </md-table-head>
          <md-table-head>
            Type
          </md-table-head>
          <md-table-head>
            Date
          </md-table-head>
        </md-table-row>
        <ImportListItem v-for="item in importsList"
          :key="item.date.toString()"
          :item="item"
          :handleErrorClick="displayErrors"
        />
      </md-table>

      <ImportErrorsDialog
        :show="!!errors.length"
        :toggle="clearErrors"
        :errors="errors">
      </ImportErrorsDialog>
    </div>
  </Layout>
</template>

<script>
import { mapState } from 'vuex';
import Layout from './Layout.vue';
import ImportListItem from './ImportListItem.vue';
import ImportErrorsDialog from './ImportErrorsDialog.vue';

export default {
  name: 'pricing-dashboard',
  components: {
    Layout,
    ImportListItem,
    ImportErrorsDialog,
  },
  data() {
    return {
      errors: [],
      loading: true,
    };
  },
  computed: {
    ...mapState({
      importsList: state => state.importsList,
      scheduledImportsList: state => state.scheduledImportsList,
    }),
  },
  methods: {
    clearErrors,
    displayErrors,
    handleError,
    handleLoaded,
  },
  mounted() {
    this.$store.dispatch('fetchInitialImportData')
      .then(this.handleLoaded, this.handleError);
  },
};

function displayErrors(errors) {
  this.errors = errors;
}

function clearErrors() {
  this.errors = [];
}

function handleError(e) {
  Object.assign(this, {
    loading: false,
    errors: e,
  });
}

function handleLoaded() {
  this.loading = false;
}
</script>

<style lang="scss" scoped>
  .separate-tables {
    margin: 4em 0;
  }
</style>
