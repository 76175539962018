import axios from 'axios';

function specPriceService() {
  let _baseURI;
  let _csrfToken;

  function init(baseURI, csrfToken) {
    _baseURI = baseURI;
    _csrfToken = csrfToken;
  }

  function post(endpoint, payload) {
    const fullURL = `${_baseURI}/${endpoint}`;
    const req = {
      headers: {
        Accept: '*/*',
        'x-csrf-token': _csrfToken,
      },
    };

    return axios.post(fullURL, payload, req);
  }

  function put(endpoint, payload) {
    const fullURL = `${_baseURI}/${endpoint}`;
    const req = {
      headers: {
        Accept: '*/*',
        'x-csrf-token': _csrfToken,
      },
    };

    return axios.put(fullURL, payload, req);
  }

  function submitFeedback(payload) {
    return post('feedback', payload);
  }

  function uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    return post('spec_prices/import', formData);
  }

  function updateSpecPrice(payload) {
    return put('spec_prices', payload);
  }

  return {
    init,
    submitFeedback,
    uploadFile,
    updateSpecPrice
  };
}

export default specPriceService;
