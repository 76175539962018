const compareMap = {
  eq: baseCompare,
  includes: multiIncludesCompare,
  inRange: rangeCompare,
  dateRange: dateRangeCompare,
  pastRange: pastRangeCompare,
};

function baseCompare(val, params) {
  return val === params;
}

function dateRangeCompare(val, params) {
  const { min, max } = params;
  return rangeCompare(Date.parse(val), {
    min: Date.parse(min),
    max: Date.parse(max),
  });
}

function funnel(filters) {
  return sale => filters.every(filter => filterFactory(filter)(sale));
}

function filterFactory(def) {
  return sale => compareMap[def.comparator](sale[def.target], def.params);
}

function multiIncludesCompare(val, params) {
  return params.reduce((cond, check) => cond && val.includes(check), true);
}

function pastRangeCompare(val, params) {
  const d = new Date();

  return rangeCompare(Date.parse(val), {
    min: d.setDate(d.getDate() - params),
    max: new Date(),
  });
}

function rangeCompare(val, params) {
  const { min, max } = params;

  if (!min && min !== 0) return val && val <= max;

  if (!max && max !== 0) return val && val >= min;

  return val && val >= min && val <= max;
}

export default {
  compareMap,
  funnel,
};
