export const commonFilters = {
  listingAttributes: [
    'past_days',
    'year',
    'max_wrist_size',
    'days_on_market',
    'box',
    'papers',
  ],
  specAttributes: [
    'band_materials',
    'dial_colors',
    'bezel_insert_materials',
    'diamond_dial',
  ],
};

export const comparatorMap = {
  array: 'includes',
  date: 'dateRange',
  numeric: 'inRange',
};

export const customFilters = {
  past_days: {
    name: 'past_days',
    target: 'purchased_date',
    comparator: 'pastRange',
    labelTemplate: 'Past ? days',
  },
};

export const genderMap = { M: 'Men', F: 'Women', U: 'Unisex' };

export const specAttributes = [
  'aftermarket_additions',
  'aftermarket_replacements',
  'alternate_reference_numbers',
  'analog_digital',
  'band_materials',
  'base_caliber',
  'bezel_features',
  'bezel_insert_colors',
  'bezel_insert_materials',
  'bezel_materials',
  'bracelet_name',
  'calendar_type',
  'case_back',
  'case_materials',
  'case_shape',
  'case_size',
  'case_thickness',
  'complications',
  'crystal_materials',
  'dial_colors',
  'diamond_dial',
  'gender',
  'jewels',
  'lug_width',
  'manuf_caliber',
  'manuf_diamonds',
  'movement_type',
  'msrp',
  'power_reserve',
  'style',
  'water_resistance',
  'years_released',
  'years_stopped',
];

export const listingAttributes = [
  'age',
  'box',
  'condition',
  'days_on_market',
  'expect',
  'listed_date',
  'manual',
  'max_wrist_size',
  'merchandising_name',
  'papers',
  'purchased_date',
  'refinishing_needed',
  'repairs_needed',
  'sale_source',
  'sale_type',
  'service_needed',
  'year',
];

export const salesTableColumns = [
  // { name: 'model_number', type: 'string', size: 'md' },
  {
    name: 'purchased_date', type: 'date', label: 'Date Sold', size: 'md',
  },
  {
    name: 'purchase_price', type: 'money', label: 'Sold Price', size: 'md',
  },
  {
    name: 'days_on_market', type: 'numeric', size: 'md', units: 'days',
  },
  {
    name: 'sku', type: 'string', label: 'SKU', size: 'lg',
  },
  { name: 'sale_type', type: 'string', size: 'lg' },
  { name: 'alternate_reference_numbers', type: 'array', size: 'lg' },
  // { name: 'sugar_id',                    type: 'string, size: 'md''},
  // { name: 'shopify_id',                  type: 'string, size: 'md''},
  // { name: 'magento_id',                  type: 'string, size: 'md''},
  {
    name: 'cogs', type: 'money', label: 'COGs', size: 'sm',
  },
  { name: 'listed_date', type: 'date', size: 'md' },
  { name: 'expect', type: 'money', size: 'sm' },
  { name: 'stars', type: 'money', size: 'sm' },
  {
    name: 'msrp', type: 'money', label: 'MSRP', size: 'sm',
  },
  { name: 'sale_source', type: 'string', size: 'lg' },
  { name: 'aftermarket_additions', type: 'boolean', size: 'md' },
  { name: 'aftermarket_replacements', type: 'boolean', size: 'md' },
  { name: 'age', type: 'string', size: 'md' },
  { name: 'analog_digital', type: 'string', size: 'md' },
  { name: 'band_materials', type: 'array', size: 'lg' },
  { name: 'base_caliber', type: 'string', size: 'md' },
  { name: 'bezel_features', type: 'array', size: 'lg' },
  { name: 'bezel_insert_colors', type: 'array', size: 'lg' },
  { name: 'bezel_insert_materials', type: 'array', size: 'lg' },
  { name: 'bezel_materials', type: 'array', size: 'lg' },
  { name: 'box', type: 'boolean', size: 'sm' },
  { name: 'bracelet_name', type: 'string', size: 'md' },
  { name: 'calendar_type', type: 'string', size: 'md' },
  {
    name: 'case_back',
    type: 'exhibition',
    label: 'Exhibition Case Back',
    size: 'md',
  },
  { name: 'case_materials', type: 'array', size: 'lg' },
  { name: 'case_shape', type: 'string', size: 'md' },
  {
    name: 'case_size', type: 'numeric', size: 'md', units: 'mm',
  },
  {
    name: 'case_thickness', type: 'numeric', size: 'md', units: 'mm',
  },
  { name: 'complications', type: 'array', size: 'lg' },
  { name: 'condition', type: 'string', size: 'md' },
  { name: 'crystal_materials', type: 'string', size: 'lg' },
  // { name: 'description',              type: 'text, size: 'md''},
  { name: 'dial_colors', type: 'array', size: 'lg' },
  { name: 'gender', type: 'gender', size: 'sm' },
  { name: 'jewels', type: 'numeric', size: 'sm' },
  {
    name: 'lug_width', type: 'numeric', size: 'md', units: 'mm',
  },
  { name: 'manual', type: 'boolean', size: 'sm' },
  {
    name: 'manuf_caliber',
    type: 'string',
    size: 'md',
    label: 'Manuf. Caliber',
  },
  {
    name: 'manuf_diamonds',
    type: 'boolean',
    size: 'md',
    label: 'Manuf. Diamonds',
  },
  {
    name: 'diamond_dial',
    type: 'boolean',
    size: 'md',
    label: 'Diamond Dial',
  },
  {
    name: 'max_wrist_size', type: 'numeric', size: 'md', units: 'in',
  },
  { name: 'merchandising_name', type: 'string', size: 'lg' },
  {
    name: 'movement_type', type: 'string', size: 'md', label: 'Movement',
  },
  { name: 'papers', type: 'boolean', size: 'sm' },
  {
    name: 'power_reserve', type: 'numeric', size: 'md', units: 'hrs',
  },
  { name: 'refinishing_needed', type: 'boolean', size: 'md' },
  { name: 'repairs_needed', type: 'boolean', size: 'md' },
  { name: 'service_needed', type: 'boolean', size: 'md' },
  { name: 'style', type: 'string', size: 'md' },
  {
    name: 'water_resistance', type: 'numeric', size: 'md', units: 'm',
  },
  { name: 'year', type: 'numeric', size: 'sm' },
  { name: 'years_released', type: 'string', size: 'md' },
  { name: 'years_stopped', type: 'string', size: 'md' },
];

export const specPriceTableColumns = [
  { title: 'CCSpec Number', name: 'spec_number' },
  { title: 'Base Price', name: 'base_price', type: 'money' },
  { title: 'Extra Adder', name: 'extra_adder', type: 'money' },
  { title: 'Box Adder', name: 'box_adder', type: 'money' },
  { title: 'Papers Adder', name: 'papers_adder', type: 'money' },
  { title: 'Condition Reducer', name: 'condition_reducer', type: 'money' },
  { title: 'Procurement Price', name: 'procurement_price', type: 'money' },
  { title: 'Procurement Box Price', name: 'procurement_box', type: 'money' },
  { title: 'Procurement Papers Price', name: 'procurement_papers', type: 'money' },
  { title: 'Current Inventory', name: 'current_inventory', type: 'integer' },
  { title: 'Max on Hand Qty', name: 'max_on_hand_count', type: 'integer' },
];

export const specPriceHistorySpecificTableColumns = [
  { title: 'Date Added', name: 'added_at', type: 'date' },
  { title: 'Date Replaced', name: 'replaced_at', type: 'date' },
];

export const specPriceImportErrors = {
  csv_missing_headers: "CSV is missing headers",
  csv_all_rows_invalid: "All rows in the CSV are invalid, no spec prices imported",
  failed_to_parse_csv: "Invalid file, failed to parse CSV",
  failed_to_read_file: "Failed to read file",
  failed_to_convert_line_endings: "Failed to parse file",
  validation_error: "Data validation error",
  row_data_invalid: "Data validation error",
};

export const validEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
