<template>
  <md-menu md-size="medium" :md-offset-x="300" >
    <md-button md-menu-trigger class="menu-button">Recent Sales</md-button>
    <md-menu-content>
      <md-menu-item v-for="opt in options"
        v-bind:key="opt"
        :disabled='disabled(opt)'
        @click="process(opt)">
          {{opt}}
        </md-menu-item>
      <md-menu-item >
        <md-field>
          <label>Custom Value</label>
          <md-input @input='process'></md-input>
        </md-field>
      </md-menu-item>
    </md-menu-content>
  </md-menu>
</template>

<script>

export default {
  name: 'past-days-menu',
  props: {
    filter: Object,
    handler: Function,
  },
  data() {
    return {
      options: [90, 30, 60],
    };
  },
  computed: {
    value: function() { return this.filter.value },
  },
  methods: {
    disabled,
    process,
  }
}

function disabled(val) {
  return val == this.value
}

function process(newValue) {
  const numValue = Number(newValue);
  this.filter.handler(numValue)
}

</script>

<style lang="scss" scoped>
  .menu-button {
    width: 100%;
  }
</style>
