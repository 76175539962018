import Vue from 'vue/dist/vue';
import VueRouter from 'vue-router';
import VueMaterial from 'vue-material';
// import VueHighCharts from 'vue-highcharts';
import HighchartsVue from 'highcharts-vue'
import 'vue-material/dist/vue-material.min.css';
import Imports from './components/Imports.vue';
import PricingDashboard from './components/PricingDashboard.vue';
import SpecPrices from './components/SpecPrices.vue';
import store from './stores/historicSales';
import filters from './vueFilters';
import '../styles/app.scss';

Vue.use(VueRouter);
Vue.use(VueMaterial);
// Vue.use(VueHighCharts);
Vue.use(HighchartsVue)
Object.entries(filters).forEach(([name, filter]) => Vue.filter(name, filter));

const initView = (el, props) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

  const routes = [
    { path: '/', redirect: '/pricing' },
    { path: '/imports', component: Imports },
    { path: '/pricing', component: PricingDashboard, props },
    { path: '/spec_prices', component: SpecPrices, props },
  ];

  const router = new VueRouter({ routes });

  store.commit('setCsrf', csrfToken);

  return new Vue({ el, router, store });
};

Object.assign(window, { initView });
