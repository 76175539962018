import { formatMoney } from '../helpers';
import salesCalculator from './salesCalculator';

function salesChartBuilder(sales) {
  // const { sales, handler } = component.$props;
  const model = sales[0]._model_name;
  const dates = sales.map(sale => Date.parse(sale.purchased_date));
  const points = sales.map(sale => ({
    ...sale,
    x: Date.parse(sale.purchased_date),
    y: sale.purchase_price,
    currency: formatMoney(sale.purchase_price),
    daysOnMarket: sale.days_on_market,
  }));
  const minDate = Math.min(...dates);
  const maxDate = Math.max(...dates);

  const vals = points.map(x => x.purchase_price);
  const minVal = Math.min(...vals);
  const avg = salesCalculator.avg(vals);
  const stdev = salesCalculator.stdev(vals, avg);

  const meanLine = [
    [minDate, avg],
    [maxDate, avg],
  ];
  const stddevLower = [
    [minDate, avg - stdev],
    [maxDate, avg - stdev],
  ];
  const stddevUpper = [
    [minDate, 2 * stdev],
    [maxDate, 2 * stdev],
  ];

  return {
    title: {
      text: `${model} Activity`,
    },
    xAxis: {
      title: { text: 'Date' },
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e',
        month: '%b \'%y',
        year: '%Y',
      },
      min: minDate,
      max: maxDate,
    },
    yAxis: {
      title: { text: 'Price ($)' },
      floor: minVal - stdev,
    },
    tooltip: {
      headerFormat: '<strong>${point.y:f}</strong><br>',
      pointFormat: '{point.x:%b %e, %Y}<br />DOM: {point.daysOnMarket}',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillColor: 'rgba(0, 177, 176, 0.10)',
        dashStyle: 'Dot',
        enableMouseTracking: false,
        lineColor: '#2259A8',
        lineWidth: 1,
        marker: {
          fillColor: 'transparent',
          lineColor: 'transparent',
          enabled: false,
        },
        showInLegend: false,
        stacking: 'normal',
        states: {
          hover: { lineWidth: 0 },
        },
      },
      scatter: {
        marker: {
          symbol: 'diamond',
          radius: 4,
        },
        // point: {
        //   events: {
        //     click() { handler(this.options) },
        //   },
        // },
      },
    },
    series: [
      {
        type: 'area',
        data: stddevUpper,
      },
      {
        type: 'area',
        fillColor: 'transparent',
        data: stddevLower,
      },
      {
        type: 'scatter',
        name: 'C&C Sales',
        color: '#272626',
        data: points.filter(sale => sale.sale_type === 'Crown & Caliber'),
      },
      {
        type: 'scatter',
        name: 'Partner Sales',
        color: '#F9A41A',
        data: points.filter(sale => sale.sale_type !== 'Crown & Caliber'),
      },
      {
        type: 'line',
        name: 'Average',
        color: '#9B9B9B',
        data: meanLine,
        lineWidth: 1,
        dashStyle: 'LongDash',
        marker: { enabled: false },
        states: {
          hover: { lineWidth: 0 },
        },
        enableMouseTracking: false,
      },
    ],
  };
}

export default salesChartBuilder;
