import browser from '../services/browserService';
import filterFactory from '../services/filterFactory';
import pricingDashboardService from '../services/pricingDashboardService';
import salesCalculator from '../services/salesCalculator';
import {
  comparatorMap,
  customFilters,
  salesTableColumns,
  specAttributes,
} from '../constants';
import { formatDate, humanize, unique } from '../helpers';

function pricingDashboardStore() {
  const service = pricingDashboardService();

  const state = {
    brands: [],
    columns: buildColumnList(),
    fetching: false,
    filteredSales: [],
    filteredSalesSummary: {},
    filters: [],
    initiated: false,
    models: [],
    modelNumbers: [],
    previewImage: null,
    relevantColumns: [],
    sales: [],
    salesSummary: {},
    selectedModelNumber: null,
    selectedSpec: '',
    specs: [],
    showDialog: false,
    showTable: false,
    userRole: null,
  };

  const actions = {
    addFilter,
    applyFilters,
    applyUrlParams,
    clearFilters,
    filterUpdaterFactory,
    getRelevantColumns,
    handleModelNumberChange,
    handleSpecChange,
    hideImportDialog,
    importFile,
    init,
    removeFilter,
    resetFilterDrawer,
    resetPreview,
    showImportDialog,
    submitFeedback,
    toggleTableDialog,
    updateState,
  };

  function addFilter(filter) {
    const { name, target } = filter;
    const column = state.columns.find(x => x.name === target);
    const label = column ? column.label : humanize(name);
    const newFilter = {
      label,
      ...filter,
    };
    const filters = [...state.filters, newFilter];
    applyFilters(filters);
  }

  function specFilterFactory(spec) {
    return x => (spec ? x.spec_id === spec : true);
  }

  function applyFilters(filters, selectedSpec = state.selectedSpec) {
    const funnel = filterFactory.funnel(filters);
    const specFilter = specFilterFactory(selectedSpec);
    const filteredSales = state.sales.filter(x => specFilter(x) && funnel(x));
    const filteredSalesSummary = selectedSpec || filters.length
      ? salesCalculator.summarizeSales(filteredSales)
      : {};
    const relevantColumns = getRelevantColumns(filteredSales);

    updateState({
      filters,
      filteredSales,
      filteredSalesSummary,
      relevantColumns,
      selectedSpec,
      fetching: false,
    });
    updateUrlState();
  }

  function applyModelInfoChange(modelNumber = {}) {
    setFetching(true);
    updateState({ selectedModelNumber: modelNumber });
    return service.getSales(
      modelNumber.brand_name,
      modelNumber._model_name,
      modelNumber.model_number
    ).then((res) => {
      setSales(res);
      return new Promise(f => f());
    });
  }

  function findModelNumber(modelNumber) {
    return state.modelNumbers.find(x => x.model_number === modelNumber);
  }

  function applyUrlParams() {
    const urlParams = browser.params()
      .replace(/^\?/, '')
      .split('&')
      .reduce(parseUrlParam, { filters: [] });

    const brandParam = urlParams.model_number || '';
    const selectedModelNumber = brandParam ? findModelNumber(brandParam) : {};
    const selectedSpec = urlParams.ccspec || '';

    updateState({
      selectedModelNumber,
      selectedSpec,
    });

    if (!brandParam) return new Promise(f => f());

    return applyModelInfoChange(selectedModelNumber)
      .then(() => {
        applyFilters(urlParams.filters, urlParams.ccspec);
        return new Promise(f => f());
      });
  }

  function buildColumn(col) {
    const label = col.label || humanize(col.name);
    return { ...col, label };
  }

  function buildColumnList() {
    return salesTableColumns.map(buildColumn);
  }

  function checkDirtyFilterDrawer(obj) {
    const {
      selectedModelNumber,
      selectedSpec,
      filters = [],
    } = { ...state, ...obj };

    return [
      selectedModelNumber,
      selectedSpec,
      filters.length,
    ].reduce((p, c) => c || !!p, false);
  }

  function clearFilters() {
    applyFilters([], null);
  }

  function getComparator(type) {
    return comparatorMap[type] || 'eq';
  }

  function filterHasValue(value, type) {
    switch (type) {
      case 'boolean':
        return value || value === false;
      case 'date':
      case 'numeric':
        return value.min || value.max;
      case 'array':
        return value && !!value.length;
      default:
        return value;
    }
  }

  function filterUpdaterFactory(filter) {
    const {
      name,
      target,
      type,
      comparator,
    } = filter;
    return (value) => {
      const existing = state.filters.find(x => x.name === name);
      const hasValue = filterHasValue(value, type);

      if (existing) {
        if (hasValue) {
          const filters = state.filters
            .map(x => (x.name === name ? { ...x, params: value } : x));
          applyFilters(filters);
        } else {
          const without = state.filters.filter(x => x.name !== name);
          applyFilters(without);
        }
      } else if (hasValue) {
        addFilter({
          ...filter,
          target: target || name,
          comparator: comparator || getComparator(type),
          params: value,
        });
      }
    };
  }

  function findStandardFilter(key) {
    const column = salesTableColumns.find(x => x.name === key);

    return !column ? null : {
      name: key,
      target: key,
      comparator: getComparator(column.type),
      label: column.filter || humanize(column.name),
      paramsType: column.type,
    };
  }

  function flatten(arr) {
    return arr.reduce(
      (out, n) => (Array.isArray(n) ? [...out, ...n] : [...out, n]),
      []
    );
  }

  function getRelevantColumns(sales) {
    if (!sales.length) return [];
    return salesTableColumns.reduce((arr, { name }) => {
      const vals = flatten(sales.map(s => s[name]));
      const relevant = unique(vals).length > 1;
      return relevant ? [...arr, name] : arr;
    }, []);
  }

  function handleModelNumberChange(modelNumber = {}) {
    if (modelNumber.model_number) {
      applyModelInfoChange(modelNumber)
        .then(clearFilters);
    }
  }

  function handleSpecChange(spec) {
    if (spec) {
      resetSpecFilters(spec);
    } else {
      updateState({ filteredSales: state.sales });
      applyFilters(state.filters);
    }
  }

  function hideImportDialog() {
    updateState({ showDialog: false });
  }

  function importFile(file, success, fail) {
    const errors = validateFile(file);

    if (!errors) {
      service.uploadFile(file)
        .then(() => {
          success();
          updateState({ showDialog: false });
        })
        .catch(fail);
    } else {
      fail(errors);
    }
  }

  function init(data) {
    const {
      baseUri, brands, models, modelNumbers, specs, userRole,
    } = data;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    service.init(baseUri, csrfToken);
    updateState({
      brands, models, modelNumbers, specs, userRole,
    });
    actions.applyUrlParams()
      .then(() => updateState({ initiated: true }));
  }

  function parseUrlParam(obj, pair) {
    const [key, urlValue] = pair.split('=');
    const filter = customFilters[key] || findStandardFilter(key);
    if (filter) {
      const encoded = decodeURIComponent(urlValue);
      const params = parseUrlParamValue(encoded, filter.paramsType);
      const filters = [...obj.filters, {
        ...filter,
        params,
      }];
      return { ...obj, filters };
    }

    return { ...obj, [key]: decodeURIComponent(urlValue) };
  }

  function parseUrlRangeParam(value) {
    const [min, max] = value.split('-')
      .map(x => (x === 'null' ? null : x));
    return { min, max };
  }

  function parseUrlParamValue(value, type) {
    switch (type) {
      case 'array':
        return value.split(',');
      case 'boolean':
        return value === 'true';
      case 'date':
        return parseUrlRangeParam(value);
      case 'numeric':
        return parseUrlRangeParam(value);
      default:
        return value;
    }
  }

  function removeFilter(target) {
    const filters = state.filters
      .filter(f => f.target !== target.target);

    applyFilters(filters);
  }

  function resetFilterDrawer() {
    updateState({
      selectedModelNumber: null,
      selectedSpec: null,
      filters: [],
    });
  }

  function resetSpecFilters(spec) {
    const filters = state.filters
      .filter(x => !specAttributes.includes(x.target));
    applyFilters(filters, spec);
  }

  function resetPreview() {
    updateState({ previewImage: null });
  }

  function responseData(res = {}) {
    const { data = [] } = res;
    return data;
  }

  function setFetching(fetching) {
    updateState({ fetching });
  }

  function setSales(res) {
    const data = responseData(res);
    const specs = unique(data.map(x => x.spec_id));
    updateState({
      filteredSales: data,
      sales: data,
      salesSummary: salesCalculator.summarizeSales(data),
      specs,
    });
    updateUrlState();
  }

  function modelInfo() {
    return Object.values(state.selectedModelNumber).join(' ');
  }

  function submitFeedback({ message, from }) {
    const payload = {
      message,
      from,
      model_info: modelInfo(),
      url: browser.href(),
    };

    return service.submitFeedback(payload);
  }

  function showImportDialog() {
    updateState({ showDialog: true });
  }

  function toggleTableDialog() {
    updateState({ showTable: !state.showTable });
  }

  function updateState(obj) {
    const dirty = checkDirtyFilterDrawer(obj);
    Object.assign(state, obj, { dirty });
  }

  function toUrlFilterParams({ name, comparator, params }) {
    let value;

    switch (comparator) {
      case 'inRange':
        value = `${params.min}-${params.max}`;
        break;
      case 'dateRange':
        value = `${formatDate(params.min)}-${formatDate(params.max)}`;
        break;
      default:
        value = encodeURIComponent(params);
        break;
    }

    return `${name}=${value}`;
  }

  function urlParamsReducer(params, { key, value }) {
    return value ? [...params, `${key}=${value}`] : params;
  }

  function updateUrlState() {
    if (state.initiated) {
      const modelParams = [
        {
          key: 'model_number',
          value: state.selectedModelNumber
                  && state.selectedModelNumber.model_number,
        },
        { key: 'ccspec', value: state.selectedSpec },
      ].reduce(urlParamsReducer, []);
      const filterParams = state.filters.map(toUrlFilterParams);
      const urlParams = [...modelParams, ...filterParams];
      const urlState = `/#/pricing?${urlParams.join('&')}`;
      window.history.replaceState({}, 'PricingDashboard', urlState);
    }
  }

  function validateFile(file) {
    return file.type === 'text/csv' ? null : 'File type must be CSV';
  }

  return {
    service,
    state,
    actions,
  };
}

export default pricingDashboardStore;
