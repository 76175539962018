<template>
  <div class="vb-table-wrapper">
    <vue-bootstrap-table
      ref="vb"
      :columns="columns"
      :ajax="ajax"
      :show-filter="true"
      :selectable="false"
      :show-column-picker="false"
      :sortable="true"
      :paginated="true"
      :pageSize="pageSize">
    </vue-bootstrap-table>
  </div>
</template>

<script>
import VueBootstrapTable from "vue2-bootstrap-table2";

export default {
  name: 'vb-table',
  components: {
    VueBootstrapTable,
  },
  props: {
    baseUri: String,
    endpoint: String,
    columns: Array,
    dataFormatter: Function,
    pageSize: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  computed: {
    ajax,
  },
  methods: {
    resetPage: resetPage,
    refresh: refresh,
  },
  created: function () {
    this.$on('ajaxLoadedEvent',
      function(data) {
        if (this.dataFormatter) {
          this.dataFormatter(data.data);
        }
      }
    );
    this.$on('cellDataModifiedEvent',
      function(originalValue, newValue, columnTitle, entry) {
        this.$emit("update", { value: newValue, columnTitle, entry })
      }
    );
  },
}

function ajax() {
  const fullURL = `${this.baseUri}/${this.endpoint}`;
  return {
    enabled: true,
    url: fullURL,
    method: "GET",
    delegate: true,
    axiosConfig: {}
  }
}

function resetPage() {
  this.$refs.vb.page = 1;
}

function refresh() {
  this.$refs.vb.refresh();
}
</script>

<style lang="scss" scoped>
  $fa-font-path: "../../../../node_modules/@fortawesome/fontawesome-free/webfonts";
  @import "../../../../node_modules/@fortawesome/fontawesome-free/scss/solid";

  .vb-table-wrapper /deep/ {
    @import "../../styles/bootstrap.min";

    h3 {
      font-weight: 600 !important;
      margin-left: 5px;
    }

    .btn-outline-primary {
      color: rgb(255, 90, 0) !important;
      border-color: rgb(255, 90, 0) !important;
    }
    .btn-outline-primary:not(:disabled):not(.disabled).active {
      color: rgb(255, 255, 255) !important;
      background-color: rgb(255, 90, 0) !important;
      border-color: rgb(255, 90, 0) !important;
    }
    .btn:hover, .btn:active, .btn:focus {
      color: rgb(255, 255, 255) !important;
      background-color: rgb(255, 90, 0) !important;
      border-color: rgb(255, 90, 0) !important;
    }
    .btn:active, .btn:focus {
      box-shadow: 0 0 7px rgb(255, 90, 0) !important;
    }
    input.form-control {
      width: 2rem;
    }
    input{
      height: 30px !important;
    }
    .btn{
      height: 30px;
      padding: 5px !important;
      font-size: 12px !important;
    }
    .btn-danger::before{
      content: "Cancel";
    }
    .btn-primary::before{
      content: "Save";
    }
  }
</style>
