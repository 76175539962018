import client from '../../services/apiClient';

const importListItemPresenter = item => ({
  date: new Date(item.created_at),
  importType: item.import_type,
  importErrors: item.import_errors,
  processed: item.processed,
  successful: item.successful,
});

const scheduledImportPresenter = data => ({
  id: data.id,
  failedAt: data.failed_at,
  lastError: data.last_error,
  nextRun: new Date(data.run_at),
});

const fetchInitialImportData = ({ commit }) => {
  const fetchPastImports = client.fetchImports()
    .then((data) => {
      const mappedItems = data.map(importListItemPresenter);
      commit('setImports', mappedItems);
    });

  const fetchScheduledImports = client.fetchScheduledImports()
    .then((data) => {
      const mappedItems = data.map(scheduledImportPresenter);
      commit('setScheduledImports', mappedItems);
    });

  return Promise.all([fetchPastImports, fetchScheduledImports]);
};

const signOut = ({ state }, router) => {
  const { csrfToken } = state;
  client.signOut({ csrfToken }).then(() => router.go('/'));
};

export default {
  fetchInitialImportData,
  signOut,
};
