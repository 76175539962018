<template>
  <div>
    <Layout>
      <div slot="header" class="pd-widget">
        <div class="spec-price-table-header">
          <h2>{{ state.showHistory ? 'Spec Price Change History' : 'Active Spec Prices' }}</h2>
        </div>
        <div class="pd-widget-buttons" align="right">
          <md-button class="md-accent" @click="toggleFeedbackDialog(true)">
            Feedback
          </md-button>
          <md-button class="md-accent" @click="toggleHistoryTable">
            {{ state.showHistory ? 'Current' : 'History' }}
          </md-button>
          <md-button class="md-accent" @click="openImportDialog" v-if="userIsAdmin">
            Import
          </md-button>
        </div>
      </div>

      <VBTable
        ref="vbTable"
        slot="main"
        class="spec-price-table"
        :baseUri="baseUri"
        :endpoint="endpoint"
        :columns="columns"
        :pageSize=50
        :dataFormatter="this.specPriceDataFormatter"
        @update="updatePrice"
      />
    </Layout>

    <FeedbackDialog
      :show="feedbackDialogVisible"
      :submit="submitFeedback"
      :toggle="toggleFeedbackDialog"
    />

    <ImportDialog :show="state.showImportDialog"
      title="Import Spec Price Data"
      :onSubmit="importFile"
      :onClose="closeImportDialog"
    />

    <ImportResultDialog
      :show="state.showResultDialog"
      :result="importResult"
      :onClose="closeResultDialog"
    />
  </div>
</template>

<script>
import Layout from './Layout.vue';
import FeedbackDialog from './FeedbackDialog.vue';
import ImportDialog from './ImportDialog.vue';
import ImportResultDialog from './SpecPriceImportResultDialog.vue';
import SpecPriceStore from '../stores/specPriceStore.js';
import VBTable from "./VBTable.vue";
import specPriceDataFormatter from '../services/specPriceDataFormatter';
import {
  specPriceTableColumns,
  specPriceHistorySpecificTableColumns,
} from '../constants';

export const store = SpecPriceStore();

export default {
  name: 'spec-prices',
  components: {
    FeedbackDialog,
    ImportDialog,
    ImportResultDialog,
    Layout,
    VBTable,
  },
  props: {
    baseUri: String,
    userRole: String,
  },
  beforeMount() {
    store.actions.init(this.$props);
    this.$store.commit("setUserRole", this.userRole);
  },
  data: () => ({
    store,
    state: store.state,
    feedbackDialogVisible: false,
    specPriceTableColumns: specPriceTableColumns,
    specPriceHistorySpecificTableColumns: specPriceHistorySpecificTableColumns,
    importResult: { imported: 0, total: 0, errors: [] },
  }),
  computed: {
    userIsAdmin,
    endpoint,
    columns,
    specPriceHistoryTableColumns,
  },
  methods: {
    closeImportDialog: closeImport,
    closeResultDialog: store.actions.closeResultDialog,
    importFile: store.actions.importFile,
    openImportDialog: store.actions.openImportDialog,
    openResultDialog: store.actions.openResultDialog,
    submitFeedback: store.actions.submitFeedback,
    toggleFeedbackDialog,
    toggleHistoryTable: toggleHistoryTable,
    specPriceDataFormatter: specPriceDataFormatter,
    importCompleted: importCompleted,
    specPriceDataWithEditableColumns,
    updatePrice,
  },
  created: function () {
    this.$on('importCompleted', function(result) { this.importCompleted(result) });
  },
};

function defaultImportResult() {
  return { imported: 0, total: 0, errors: [] };
}

function userIsAdmin() {
  return this.userRole === 'admin';
}

function endpoint() {
  if (this.state.showHistory) {
    return 'spec_prices/history';
  } else {
    return 'spec_prices/current';
  }
}

function columns() {
  if (this.state.showHistory) {
    return this.specPriceHistoryTableColumns;
  } else {
    return this.specPriceDataWithEditableColumns();
  }
}

function specPriceHistoryTableColumns() {
  return [...this.specPriceDataWithEditableColumns(), ...specPriceHistorySpecificTableColumns];
}

function specPriceDataWithEditableColumns() {
  if (this.userIsAdmin) {
    const data = this.specPriceTableColumns;
    const indexOfEditingColumn = data.findIndex(column => column.name === 'max_on_hand_count');
    data[indexOfEditingColumn].editable = true;
    return data;
  }
  
  return this.specPriceTableColumns;
}

function toggleFeedbackDialog(bool=false) {
  this.feedbackDialogVisible = bool;
}

function toggleHistoryTable() {
  this.store.actions.toggleHistoryTable();
  this.$refs.vbTable.resetPage();
}

function closeImport() {
  this.store.actions.closeImportDialog();
  this.$refs.vbTable.refresh();
}

function importCompleted(payload) {
  this.importResult = payload;
}

function updatePrice(data) {
  store.actions.updateMaxOnHandCount({ ccspec_number: data.entry.spec_number, max_on_hand_count: data.value })
}
</script>

<style lang="scss" scoped>
  .spec-price-table-header {
    flex-basis: 50%;

    h2 {
      text-align: center;
    }
  }

  .pd-widget {
    display: flex;
  }

  .pd-widget-buttons {
    flex-basis: 50%;
  }

  .spec-price-table /deep/ {
    @import "../../styles/bootstrap.min";
  }
</style>
